import { IconButton, Paper, TextField, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import { getEnvApiUrl } from 'config/env';
import { IRootState } from 'config/store';
import get from 'lodash/get';
import moment, { Moment } from 'moment';
import React, { useState, useEffect } from 'react';
import { FormContext, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getGroupsDevices } from 'shared/auth/auth-utils';
import { ICalibrationDeliveryFormResponse } from 'shared/model/calibration.model';
import { errorNotification, successNotification } from 'shared/reducers/notifierSlice';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import SelectDevice from 'shared/widgets/form/selectDevice';
import { v4 as uuid } from 'uuid';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { APP_LOCAL_DATE_FORMAT } from 'shared/utils/date-utils';
import { Alert } from '@material-ui/lab';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { settingsTo } from 'modules/settings';

const apiUrl = getEnvApiUrl();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    content: {
      minWidth: '50vw',
      minHeight: '60vh',
      [theme.breakpoints.up('lg')]: {
        minWidth: '30vw'
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%'
      }
    },
    btnbar: {
      '&>*': {
        marginRight: theme.spacing(1)
      }
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    btnDivider: {
      marginLeft: theme.spacing(2)
    },
    deviceInfo: {
      position: 'relative',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(0),
      color: theme.palette.grey[500],
      zIndex: 12
    }
  })
);

type IDeliveryToUpdate = {
  idCalibration: string;
  calibration_date: string;
  created_at: string;
  device_name: string;
  quantity: number;
  poi_name: string;
  type: string;
  idDevice: string;
};
type IDeliveryToCreate = {
  name: string;
  idDevice: string;
  id: string;
};

const defaultValues: ICalibrationDeliveryFormResponse = {
  date: moment(),
  silos: []
};

const CalibrationDeliveryForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const history = useHistory();
  const [date, setDate] = useState<Moment>(moment());
  const allGroups = useSelector(({ group }: IRootState) => group.groups);
  const devices = getGroupsDevices(allGroups);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [devicesToDeliverIds, setDevicesToDeliverIds] = useState<string[]>([]); // create ids for  hook form
  const [deliveriesToUpdate, setDeliveriesToUpdate] = useState<IDeliveryToUpdate[]>([]); // existing deliveries for this day, to be updated with a new hour
  const [deliveriesToCreate, setDeliveriesToCreate] = useState<IDeliveryToCreate[]>([]); //  list of deliveries to create
  const [deviceInError, setDeviceInError] = useState<string[]>([]);

  const form = useForm<ICalibrationDeliveryFormResponse>({
    mode: 'onChange',
    defaultValues
  });

  useFieldArray({
    control: form.control,
    name: 'silos'
  });

  const watch = form.watch();
  useEffect(() => {
    form.triggerValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceInError]);

  useEffect(() => {
    setDeviceInError([]);
  }, [date]);

  const onMore = () => {
    setDevicesToDeliverIds((oldIds: string[]) => [...oldIds, uuid()]); // create a new id for the device to deliver
  };

  const onClose = (id: string) => () => {
    // remove the id for the device to deliver
    setDevicesToDeliverIds(oldIds => oldIds.filter(deliverId => deliverId !== id));

    // retrieve the device id
    const deviceId = deliveriesToCreate.find(d => d.id === id)?.idDevice;
    if (!deviceId) return;

    // remove the id from the list of deliveries to create (device can be mention several times so we delete only the concerned one)
    setDeliveriesToCreate(previousDevices =>
      previousDevices.filter(delivery => delivery.id !== id)
    );

    // check if the device is the last remaining for this device in the list of deliveries to create, if it is the case, we can also remove it from the list of deliveries to update
    const isLastDevice = deliveriesToCreate.filter(d => d.idDevice === deviceId).length < 2;
    const deviceInUpdate = deliveriesToUpdate.find(delivery => delivery.idDevice === deviceId);

    if (isLastDevice && deviceInUpdate) {
      setDeliveriesToUpdate(previousDeliveries =>
        previousDeliveries.filter(delivery => delivery.idDevice !== deviceId)
      );
    }
  };
  const onAnother = () => {
    setSuccess(false);
  };

  const isBefore = (a: Date, b: Date) => a < b;

  const checkHourExists = (id: string, hour: string, mode: string | undefined) => {
    const allDevicesWithHour = deliveriesToCreate
      .map(delivery => {
        return {
          id: delivery.id,
          reference: delivery.idDevice,
          hour: get(watch, `silos[${delivery.id}].time`)
        };
      })
      .concat(
        deliveriesToUpdate.map(delivery => {
          return {
            id: delivery.idCalibration,
            reference: delivery.idDevice,
            hour: get(watch, `silos[${delivery.idCalibration}].time`)
          };
        })
      )
      .filter(delivery => delivery.id !== id);
    // check if the same hour exist for the same device in the allDevicesWithHour array
    if (mode === 'update') {
      return allDevicesWithHour.some(
        d =>
          d.reference === deliveriesToUpdate.find(del => (del.idCalibration = id))?.idDevice &&
          d.hour === hour
      );
    } else {
      return allDevicesWithHour.some(
        d => d.reference === get(watch, `silos[${id}].device`)?.value && d.hour === hour
      );
    }
  };

  const getDeliveriesThisDay = async (date: Moment, device: string) => {
    //nico ts
    if (!date.isValid()) return [];
    const startDate = moment(date).startOf('day').toISOString();
    const endDate = moment(date).endOf('day').toISOString();
    const response = await axios.get(
      // @todo : use a qparam iddevice for this route and correct the documentation
      `${apiUrl.replace('v1', 'v2')}/calibrations?start_date=${startDate}&end_date=${endDate}`
    );
    return response.data.filter((calib: IDeliveryToUpdate) => calib.idDevice === device);
  };

  const displayDeliveriesThisDay = async (date: Moment, device: IDeliveryToCreate) => {
    const existingDeliveries = await getDeliveriesThisDay(date, device.idDevice);
    if (existingDeliveries.length) {
      setDeliveriesToUpdate(oldDel => {
        // Si deliveriesToUpdate est vide, ajouter simplement existingDeliveries
        if (oldDel.length === 0) {
          return existingDeliveries;
        }
        const uniqueNewDeliveries = new Set([
          ...oldDel.map(delivery => delivery.idCalibration),
          ...existingDeliveries.map((delivery: IDeliveryToUpdate) => delivery.idCalibration)
        ]);
        const updatedDeliveries = Array.from(uniqueNewDeliveries).map(idCalibration =>
          [...oldDel, ...existingDeliveries].find(
            delivery => delivery.idCalibration === idCalibration
          )
        );
        return updatedDeliveries;
      });
    }
  };

  const save = async (responses: ICalibrationDeliveryFormResponse) => {
    setSaving(true);

    // Update existing deliveries
    const updatePromises = deliveriesToUpdate.map(async delivery => {
      const time = get(responses.silos, `${delivery.idCalibration}.time`);
      const toSend = {
        delivery_date: date
          .set('hour', Number(time.slice(0, 2)))
          .set('minute', Number(time.slice(3, 5)))
          .set('second', 0)
          .set('millisecond', 0)
          .toDate()
          .toISOString(),
        idDelivery: delivery.idCalibration
      };
      try {
        await axios.patch(
          `${apiUrl.replace('v1', 'internal')}/calibration-delivery/${delivery.idCalibration}`,
          toSend
        );
        return { status: 'fulfilled' };
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        return { status: 'rejected', reason: errorMsg };
      }
    });

    // Create new deliveries
    const createPromises = deliveriesToCreate.map(async delivery => {
      const silo = responses.silos[delivery.id];
      const siloData = {
        id: silo.device.value,
        tonnage: Number(silo.tonnage)
      };

      const toSend = {
        bypass_send_email: true,
        date: date
          .set('hour', Number(silo.time.slice(0, 2)))
          .set('minute', Number(silo.time.slice(3, 5)))
          .set('second', 0)
          .set('millisecond', 0)
          .toDate()
          .toISOString(),
        timeSlot: Number(silo.time.slice(0, 2)) > 12 ? 'afternoon' : 'morning',
        source: 'delivery',
        silos: [siloData] // Envoi d'un seul silo à la fois
      };

      try {
        await axios.post(`${apiUrl}/orders/`, toSend);
        return { status: 'fulfilled' };
      } catch (error) {
        // @ts-ignore
        if (error.response?.data?.message.includes('silos')) {
          // @ts-ignore
          const errorDevices = error.response?.data?.message.replace(/\s/g, '').split(':')[1];
          setDeviceInError(errorDevices ?? []);
        }
        const errorMsg = getRequestErrorMessage(error);
        return { status: 'rejected', reason: errorMsg };
      }
    });

    // Combine both promises
    Promise.allSettled([...updatePromises, ...createPromises]).then(results => {
      const errors = results.filter(result => result.status === 'rejected');
      if (errors.length === 0) {
        dispatch(successNotification(t('orders.saveSuccess')));
      } else {
        const errorMessages = errors.map(error => error.reason).join('\n');
        dispatch(errorNotification(`${errorMessages}\n`));
      }

      setSaving(false);
      if (errors.length === 0) {
        setSuccess(true);
        form.reset();
        history.push(settingsTo);
      }
    });
  };

  const onSubmit = form.handleSubmit(save);
  return (
    <Box p={1}>
      <Paper elevation={3}>
        <FormContext {...form}>
          <form className={classes.root} onSubmit={onSubmit} autoComplete="off">
            <Box textAlign="center">
              <Typography variant="h5">{t('orders.delivery')}</Typography>
            </Box>
            <Grid container justify="center" alignItems="center">
              <Grid item className={classes.content}>
                {success ? (
                  <Box p={2} textAlign="center">
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth={isSmall}
                      onClick={onAnother}
                    >
                      <AddIcon /> {t('orders.another')}
                    </Button>
                  </Box>
                ) : (
                  <>
                    <Box marginBottom={2}>
                      <KeyboardDatePicker
                        format={APP_LOCAL_DATE_FORMAT}
                        value={date}
                        fullWidth
                        onChange={(value: any) => {
                          setDeliveriesToUpdate([]);
                          deliveriesToCreate.forEach(async device => {
                            displayDeliveriesThisDay(value, device);
                          });
                          setDate(value);
                        }}
                        label={t('orders.date')}
                        disabled={saving}
                        disableFuture
                      />
                    </Box>
                    {deliveriesToUpdate.map((delivery: any) => {
                      //nico ts
                      return (
                        <Paper
                          component={Box}
                          elevation={3}
                          key={delivery.idCalibration}
                          className={classes.deviceInfo}
                          style={{ border: '2px solid #2E48A7' }}
                        >
                          <Typography variant="h6" component="h6" gutterBottom>
                            {deliveriesToCreate.find(d => d.idDevice === delivery.idDevice)?.name}
                          </Typography>
                          <TextField
                            name={`silos[${delivery.idCalibration}].tonnage`}
                            label={t('string_workspace_filling_unit', {
                              value: t('orders.tonnage')
                            })}
                            fullWidth
                            type="number"
                            defaultValue={delivery.quantity}
                            disabled={true}
                            InputProps={{ inputProps: { step: 'any' } }}
                          />
                          <TextField
                            name={`silos[${delivery.idCalibration}].time`}
                            label={t('orders.time')}
                            fullWidth
                            type="time"
                            defaultValue={moment(delivery.calibration_date).format('HH:mm')}
                            disabled={saving}
                            InputProps={{ inputProps: { step: 'any' } }}
                            inputRef={form.register({
                              validate: value => {
                                const isHourExists = checkHourExists(
                                  delivery.idCalibration,
                                  value,
                                  'update'
                                );
                                if (isHourExists) {
                                  return t('order_already_exist_for_this_silo_date');
                                }

                                const dateTime = new Date(`1970-01-01T${value}:00Z`);

                                if (date && !isNaN(dateTime.getTime())) {
                                  const dateWithTime = date
                                    .set('hour', Number(value.slice(0, 2)))
                                    .set('minute', Number(value.slice(3, 5)))
                                    .set('second', 0)
                                    .set('millisecond', 0)
                                    .toDate()
                                    .toISOString();
                                  if (!isBefore(new Date(dateWithTime), new Date())) {
                                    return t('required_past_date');
                                  }
                                }

                                if (isNaN(dateTime.getTime())) {
                                  return t('required_field');
                                }

                                return true; // Retourner true si la validation est réussie
                              },
                              required: t('required_field')
                            })}
                            error={
                              form.errors.silos && form.errors.silos[delivery.id]?.time
                                ? true
                                : false
                            }
                            helperText={
                              form.errors.silos && form.errors.silos[delivery.id]?.time?.message
                            }
                          />
                        </Paper>
                      );
                    })}

                    {devicesToDeliverIds.map(id => {
                      const deviceRef = get(watch, `silos[${id}].device`)?.value;
                      const deviceTonnage = get(watch, `silos[${id}].tonnage`);
                      const currentSilo = devices.find(item => item.device_reference === deviceRef);
                      const capaMax = currentSilo?.capa_max;
                      const capaMaxMessage = capaMax &&
                        deviceTonnage &&
                        deviceTonnage > capaMax && (
                          <Trans i18nKey="orders.errors.capaMax" values={{ max: capaMax }}>
                            Capamax
                          </Trans>
                        );
                      const isDeviceSelected = !!deviceRef;

                      return (
                        <Paper
                          key={id}
                          component={Box}
                          elevation={3}
                          className={classes.deviceInfo}
                        >
                          <input type="hidden" value="id" />
                          <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onClose(id)}
                            disabled={saving}
                          >
                            <CloseIcon />
                          </IconButton>
                          <SelectDevice
                            name={`silos[${id}].device`}
                            label={t('select_silo')}
                            deviceGroupLabel={t('silo')}
                            disabled={saving || isDeviceSelected}
                            required
                            useRefDevice
                            onSelectionChange={(value: ILabelValueOption<string>) => {
                              const delivery = {
                                name: value.label,
                                idDevice: value.value,
                                id // Informations liées à hookform
                              };
                              setDeliveriesToCreate(prevDeliveries => {
                                if (!Array.isArray(prevDeliveries)) {
                                  prevDeliveries = [];
                                }
                                return [...prevDeliveries, delivery];
                              });
                              displayDeliveriesThisDay(date, delivery);
                            }}
                            validate={(value: any) => {
                              if (!value)
                                return <Trans i18nKey="required_field">Required Field</Trans>;
                              if (deviceInError.includes(value.value))
                                return (
                                  <Trans i18nKey="order_already_exist_for_this_silo_date">
                                    Delivery already exist for this silo
                                  </Trans>
                                );
                              return true;
                            }}
                            filterCombineDevice
                          />
                          <TextField
                            name={`silos[${id}].tonnage`}
                            label={t('string_workspace_filling_unit', {
                              value: t('orders.tonnage')
                            })}
                            fullWidth
                            type="number"
                            defaultValue="0"
                            disabled={saving}
                            InputProps={{ inputProps: { step: 'any' } }}
                            inputRef={form.register({
                              min: {
                                value: 0.01,
                                message: (
                                  <Trans i18nKey="should_be_above_zero">
                                    Should be greater than 0
                                  </Trans>
                                )
                              },
                              required: <Trans i18nKey="required_field">Required Field</Trans>,
                              validate: {
                                //@ts-ignore
                                doubleCapaMax: v =>
                                  !capaMax ||
                                  v < 2 * capaMax || (
                                    <Trans
                                      i18nKey="orders.errors.capaMax"
                                      values={{ max: capaMax }}
                                    >
                                      Capamax
                                    </Trans>
                                  )
                              }
                            })}
                            error={
                              (form.errors.silos && form.errors.silos[id]?.tonnage
                                ? true
                                : false) || !!capaMaxMessage
                            }
                            helperText={
                              (form.errors.silos && form.errors.silos[id]?.tonnage?.message) ||
                              capaMaxMessage
                            }
                          />
                          <TextField
                            name={`silos[${id}].time`}
                            label={t('orders.time')}
                            fullWidth
                            type="time"
                            defaultValue="00:00"
                            disabled={saving}
                            InputProps={{ inputProps: { step: 'any' } }}
                            inputRef={form.register({
                              validate: value => {
                                const isHourExists = checkHourExists(id, value, 'create');
                                if (isHourExists) {
                                  return t('order_already_exist_for_this_silo_date');
                                }

                                const dateTime = new Date(`1970-01-01T${value}:00Z`);

                                if (date && !isNaN(dateTime.getTime())) {
                                  const dateWithTime = date
                                    .set('hour', Number(value.slice(0, 2)))
                                    .set('minute', Number(value.slice(3, 5)))
                                    .set('second', 0)
                                    .set('millisecond', 0)
                                    .toDate()
                                    .toISOString();
                                  if (!isBefore(new Date(dateWithTime), new Date())) {
                                    return t('required_past_date');
                                  }
                                }

                                if (isNaN(dateTime.getTime())) {
                                  return t('required_field');
                                }

                                return true; // Retourner true si la validation est réussie
                              },
                              required: t('required_field')
                            })}
                            error={form.errors.silos && form.errors.silos[id]?.time ? true : false}
                            helperText={form.errors.silos && form.errors.silos[id]?.time?.message}
                          />
                        </Paper>
                      );
                    })}
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {deliveriesToUpdate.length > 0 && (
                        <Alert
                          severity="warning"
                          style={{ maxWidth: '30vh', wordBreak: 'break-word' }}
                        >
                          {t('delivery_exists_today')}
                        </Alert>
                      )}
                    </div>
                    <Box p={2} textAlign="center">
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth={isSmall}
                        onClick={onMore}
                        disabled={saving}
                      >
                        <AddIcon /> {t('more')}
                      </Button>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
            {!success && (
              <>
                <Divider variant="middle" className={classes.divider} />
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.btnbar}
                >
                  <Button
                    component={Link}
                    to={settingsTo}
                    startIcon={<CancelIcon />}
                    variant="contained"
                    fullWidth={isSmall}
                    disabled={saving}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={deliveriesToCreate.length === 0 || saving}
                    fullWidth={isSmall}
                  >
                    {t('save')}
                  </Button>
                </Box>
              </>
            )}
          </form>
        </FormContext>
      </Paper>
    </Box>
  );
};

export default CalibrationDeliveryForm;
