import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { LocalShipping } from '@material-ui/icons';
import { IRootState } from 'config/store';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { APP_LOCAL_DATE_FORMAT, formatDate } from 'shared/utils/date-utils';
// import { getSupportEmail } from 'shared/utils/workspace-utils';
import DataTable from 'shared/widgets/dataTable';
import TitleWithSearchField from 'shared/widgets/titleWithSearchField';
import { newCalibrationsDeliveryTo, newCalibrationsLevelTo } from '.';
import { Edit } from '@material-ui/icons';
import DeliveryDialog from 'modules/devices/actions/deliveryDialog';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import axios from 'axios';
import { getEnvApiUrl } from 'config/env';
import { DatePicker } from '@material-ui/pickers';
import { useIsAuthorised } from 'shared/auth/auth-utils';
import { ReactComponent as CalibrateIcon } from 'shared/icons/CalibrateIcon.svg';
import { fetchCalibrationInfo } from 'shared/reducers/calibrationsSlice';
import { ICalibration } from 'shared/model/calibration.model';
import CalibrationTypeFilter from './calibrationTypeFilter';

const handleDelete = async (id: string) => {
  const apiUrl = getEnvApiUrl();
  await axios.delete(`${apiUrl.replace('v1', 'internal')}/calibration-delivery/${id}`);
};

const Calibrations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calibrations = useSelector(({ calibrations }: IRootState) => calibrations.calibrations);
  const loading = useSelector(({ calibrations }: IRootState) => calibrations.loading);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [selectedRows, setSelectedRows] = useState<ICalibration[]>([]);
  const [openDeliveryeditionDialog, setOpenDeliveryeditionDialog] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(2, 'weeks').startOf('day'));
  const [typeFilter, setTypeFilter] = useState<'delivery' | 'level' | 'null'>('null');
  const columns: IDataTableColumn<ICalibration>[] = useMemo(
    () => [
      {
        selector: 'device_name',
        name: t('silo'),
        sortable: true,
        grow: 1,
        format: (row: ICalibration) => (
          <Box fontWeight="fontWeightBold">
            <Box key={row.idDevice}>{row.device_name}</Box>
          </Box>
        )
      },
      {
        selector: 'poi_name',
        name: t('group'),
        sortable: true,
        grow: 1
      },
      {
        selector: 'date',
        name: t('calibration.date'),
        grow: 1,
        sortable: true,
        format: (row: ICalibration) => formatDate(row.calibration_date, APP_LOCAL_DATE_FORMAT)
      },
      {
        selector: 'tonnage',
        name: t('string_workspace_filling_unit', { value: t('orders.tonnage') }),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (row: ICalibration) => t('number_workspace_filling_unit', { value: row.quantity })
      },
      {
        selector: 'source',
        name: t('orders.source'),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (row: ICalibration) => (row.source ? t(`orders.${row.source}`) : '')
      },
      {
        selector: 'type',
        name: t('resourcetype'),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (row: ICalibration) => t(`orders.${row.type}`)
      }
    ],
    [t]
  );

  const data = useMemo(() => {
    const result = calibrations.reduce((agg: ICalibration[], current: ICalibration) => {
      if (
        filterText.length === 0 ||
        current.device_name.toLowerCase().includes(filterText.toLowerCase())
      ) {
        agg.push(current);
      }
      return agg;
    }, [] as ICalibration[]);
    return result;
  }, [calibrations, filterText]);

  useEffect(() => {
    dispatch(fetchCalibrationInfo(startDate, typeFilter));
  }, [dispatch, startDate, typeFilter]);

  const onSucess = () => {
    dispatch(fetchCalibrationInfo(startDate, typeFilter));
    setToggleCleared(!toggleCleared);
  };

  const contextActions = selectedRows.length === 1 && (
    <Box style={{ gap: 8, display: 'flex' }}>
      <PrivateComponent resource="Calibrations" operation={['UPDATE']}>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => setOpenDeliveryeditionDialog(true)}
        >
          <Edit />
        </Button>
      </PrivateComponent>
      <PrivateComponent resource="Calibrations" operation={['DELETE']}>
        <ConfirmDelete
          onConfirm={() => handleDelete(selectedRows[0].idCalibration).then(_ => onSucess())}
          objectToReturn={selectedRows}
          size="small"
        />
      </PrivateComponent>
      <DeliveryDialog
        onSucess={onSucess}
        calibration={selectedRows[0]}
        open={openDeliveryeditionDialog}
        handleClose={() => setOpenDeliveryeditionDialog(false)}
      />
    </Box>
  );
  const actions = (
    <PrivateComponent resource="Calibrations" operation={['CREATE']}>
      <Button
        style={{ maxHeight: 32 }}
        color="primary"
        variant="contained"
        component={Link}
        to={newCalibrationsDeliveryTo}
      >
        <LocalShipping />
      </Button>
      <Button
        style={{ maxHeight: 32 }}
        color="primary"
        variant="contained"
        component={Link}
        to={newCalibrationsLevelTo}
      >
        <CalibrateIcon fill="white" />
      </Button>
    </PrivateComponent>
  );

  const title = useMemo(() => {
    const onChange = (filter: string) => {
      setFilterText(filter);
      if (filter.length === 0) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };
    const onTypeChange = (event: React.ChangeEvent<any>) => {
      setTypeFilter(event.target.value);
    };
    return (
      <Box display="flex" alignItems={'center'} style={{ gap: 16 }}>
        <TitleWithSearchField
          title={t('calibration.title', { count: 100 })}
          placeholder={t('orders.search_by_silo')}
          onChange={onChange}
          autoFocus
        />
        <CalibrationTypeFilter type={typeFilter} onChange={onTypeChange} />
        <DatePicker
          // label={t('from')}
          disableToolbar
          value={startDate}
          onChange={(value: any) => setStartDate(value)}
          format={APP_LOCAL_DATE_FORMAT}
          disableFuture
          margin="none"
          variant="inline"
          style={{ marginBottom: 8 }}
        />
      </Box>
    );
  }, [resetPaginationToggle, startDate, t, typeFilter]);

  return (
    <>
      <Box p={1}>
        <DataTable
          title={title}
          columns={columns}
          data={data}
          selectableRows={useIsAuthorised('Calibrations', ['DELETE', 'UPDATE'])}
          defaultSortField="date"
          defaultSortAsc={false}
          progressPending={loading}
          // expandOnRowClicked
          expandableRowsHideExpander
          onSelectedRowsChange={state => setSelectedRows(state.selectedRows)}
          actions={actions}
          contextActions={contextActions}
          clearSelectedRows={toggleCleared}
        />
      </Box>
    </>
  );
};

export default Calibrations;
