import React from 'react';
import PrivateRoute from 'shared/auth/private-route';
import settings from './settings';
import { newCalibrationsDeliveryTo, newCalibrationsLevelTo } from 'modules/calibrations';
import CalibrationDeliveryForm from 'modules/calibrations/CalibrationDeliveryForm';
import CalibrationLevelForm from 'modules/calibrations/CalibrationLevelForm';
export const settingsTo = '/settings';

const Routes = () => (
  <div>
    <PrivateRoute
      path={settingsTo}
      exact
      component={settings}
      resource="menu_admin"
      operation={['READ']}
    />
    <PrivateRoute
      path={newCalibrationsDeliveryTo}
      exact
      component={CalibrationDeliveryForm}
      resource="Calibrations"
      operation={['UPDATE']}
    />
    <PrivateRoute
      path={newCalibrationsLevelTo}
      exact
      component={CalibrationLevelForm}
      resource="Calibrations"
      operation={['UPDATE']}
    />
  </div>
);

export default Routes;
