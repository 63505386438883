import React from 'react';
import PrivateRoute from 'shared/auth/private-route';
import CalibrationDeliveryForm from './CalibrationDeliveryForm';
import CalibrationLevelForm from './CalibrationLevelForm';
export const newCalibrationsDeliveryTo = '/settings/new-delivery';
export const newCalibrationsLevelTo = '/settings/new-level';

const Routes = () => (
  <div>
    <PrivateRoute
      path={newCalibrationsDeliveryTo}
      exact
      component={CalibrationDeliveryForm}
      resource="Calibrations"
      operation={['UPDATE']}
    />
    <PrivateRoute
      path={newCalibrationsLevelTo}
      exact
      component={CalibrationLevelForm} // Changer en CalibrationLevelForm
      resource="Calibrations"
      operation={['UPDATE']}
    />
  </div>
);

export default Routes;
