import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { Moment } from 'moment';
import { IRule } from 'shared/model/rule.model';
import { ICalibration } from 'shared/model/calibration.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { convertDateFromServer } from 'shared/utils/date-utils';
import { errorNotification } from './notifierSlice';

const initialState = {
  loading: false,
  errorMessage: '',
  calibrations: [] as ICalibration[],
  calibration: null as ICalibration | null,
  updating: false,
  updateSuccess: false
};

export type CalibrationsState = typeof initialState;

export const slice = createSlice({
  name: 'calibrations',
  initialState,
  reducers: {
    fetchCalibrationInfoStart: state => {
      state.loading = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    fetchCalibrationInfoFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    fetchCalibrationInfoSuccess: (state, action: PayloadAction<ICalibration[]>) => {
      action.payload.forEach(item => {
        item.calibration_date = convertDateFromServer(item.calibration_date as string);
      });
      state.loading = false;
      state.calibrations = action.payload;
    },
    updateOrderStart: state => {
      state.updating = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    updateOrderFailed: (state, action: PayloadAction<string>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    updateOrderSuccess: (state, action: PayloadAction<ICalibration>) => {
      state.updating = false;
      state.updateSuccess = true;
      state.calibration = action.payload;
    }
  }
});

export default slice.reducer;

//Actions
const {
  fetchCalibrationInfoStart,
  fetchCalibrationInfoFailed,
  fetchCalibrationInfoSuccess,
  updateOrderStart,
  updateOrderFailed,
  updateOrderSuccess
} = slice.actions;

const apiUrl = getEnvApiUrl();

export const fetchCalibrationInfo =
  (start_date: Moment, typeFilter: 'delivery' | 'level' | 'null'): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchCalibrationInfoStart());
      const minDateStr = start_date.toISOString();
      const params: { [key: string]: string } = { start_date: minDateStr };
      if (typeFilter !== 'null') {
        params.type = typeFilter;
      }
      const queryString = new URLSearchParams(params).toString();
      const response: AxiosResponse<ICalibration[]> = await axios.get(
        `${apiUrl.replace('v1', 'v2')}/calibrations?${queryString}`
      );
      dispatch(fetchCalibrationInfoSuccess(response.data));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(fetchCalibrationInfoFailed(errorMsg));
      dispatch(errorNotification(errorMsg));
    }
  };

export const createOrder =
  (rule: IRule): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateOrderStart());
      const response: AxiosResponse<ICalibration> = await axios.post(`${apiUrl}/orders/`, rule);
      dispatch(updateOrderSuccess(response.data));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateOrderFailed(errorMsg));
      dispatch(errorNotification(errorMsg));
    }
  };
