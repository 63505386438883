import { createStyles, makeStyles, Theme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      alignItems: 'center'
    },
    typeFilter: {
      height: '29px',
      minWidth: '100%',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        minWidth: '10rem',
        marginLeft: theme.spacing(2)
      }
    },
    empty: {
      color: 'rgba(0, 0, 0, 0.5)'
    }
  })
);

interface ICalibrationTypeProps {
  type: 'level' | 'delivery' | 'null';
  onChange: (event: React.ChangeEvent<any>) => void;
}

const CalibrationTypeFilter = (props: ICalibrationTypeProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { type, onChange } = props;
  console.log('type', type);
  return (
    <Select
      labelId="calibraton-type-filter"
      value={type}
      onChange={onChange}
      className={classes.typeFilter}
    >
      <MenuItem value="level">
        <span className={classes.empty}>{t('level')}</span>
      </MenuItem>
      <MenuItem value="delivery">
        <div className={classes.menuItem}>
          <div>{t('orders.delivery')}</div>
        </div>
      </MenuItem>
      <MenuItem value={'null'}>
        <div className={classes.menuItem}>
          <div>{t('all')}</div>
        </div>
      </MenuItem>
    </Select>
  );
};

export default CalibrationTypeFilter;
