import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SupportIcon } from 'shared/icons/SupportIcon.svg';
import { ReactComponent as CalibrationIcon } from 'shared/icons/CalibrationIcon.svg';
import { createStyles, makeStyles, Tab, Tabs, Theme, useTheme } from '@material-ui/core';
import Calibrations from 'modules/calibrations/calibrations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 6
    },
    firstIcon: {
      marginRight: theme.spacing(1)
    },
    labelContainer: {
      width: 'auto',
      alignItems: 'center',
      padding: theme.spacing(2),
      minHeight: 'auto'
    },
    iconLabelWrapper: {
      flexDirection: 'row'
    }
  })
);
const Settings = () => {
  const classes = useStyles();
  const [valueTab, setValueTab] = useState(0);
  const theme = useTheme();
  const { t } = useTranslation();
  const tabsInfo = [
    {
      value: 'calibration.title_short',
      icon: <CalibrationIcon fill={theme.palette.primary.main} className={classes.firstIcon} />
    },
    {
      value: 'support.title',
      icon: <SupportIcon fill={theme.palette.primary.main} className={classes.firstIcon} />
    }
  ];
  return (
    <>
      <Tabs
        value={valueTab}
        onChange={(_, newValue) => {
          setValueTab(newValue);
        }}
        classes={{
          root: classes.root
        }}
      >
        {tabsInfo.map(tab => (
          <Tab
            key={tab.value}
            icon={tab.icon}
            label={t(tab.value)}
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelIcon: classes.labelContainer
            }}
          />
        ))}
      </Tabs>
      {valueTab === 0 && <Calibrations />}
      {valueTab === 1 && <Calibrations />}
    </>
  );
};

export default Settings;
